import "$styles/index.scss"
// import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

window.addEventListener("load", function() {
  document.body.classList.remove("preload")

  const interBubble = document.querySelector(".gradient__g--interactive")
  let curX = 0
  let curY = 0
  let tgX = 0
  let tgY = 0

  function move() {
    curX += (tgX - curX) / 20
    curY += (tgY - curY) / 20
    interBubble.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`
    requestAnimationFrame(move)
  }

  window.addEventListener("mousemove", (event) => {
      tgX = event.clientX
      tgY = event.clientY
  })

  move()
})

window._decrypt = function(encrypted) {
  document.write(encrypted.replace(/[a-zA-Z]/g, function(c){
    return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);
  }));
}